import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module'

export const AnalyticsContext = React.createContext();

export const AnalyticsProvider = (props) => {
    const [host, sethost] = useState("")

    
    useEffect(() => {
        sethost(window.location.host);
        TagManager.initialize({
            gtmId: 'GTM-PP7VT95'
        })
    },[]);
    const gtmPageViewEvent = async(pathname, hostname) => {
      const user = JSON.parse(localStorage.getItem("user"));
      console.log(user,"gtm")
        let ev = "virtual_page_view";
        if(hostname=="astroguru.bodhiness.com"){
            ev = ev+"_astroguru";
        }
        else if(hostname=="mobafl.bodhiness.com"){
            ev = ev+"_mobaf";
        }
        else if(hostname=="webj.bodhiness.com"){
            ev = ev+"_webj";
        }
        else if(hostname=="lokal.bodhiness.com"){
            ev = ev+"_lokal";
        }
        else if(hostname=="namah.bodhiness.com"){
            ev = ev+"_namah";
        }
        else if(hostname=="meesho.bodhiness.com"){
            ev = ev+"_meesho";
        }
        else if(hostname=="jio.bodhiness.com"){
            ev = ev+"_jio";
        }

        console.log(user?.meesho_userId,"user?.meesho_userId")
        const sendToUser = user?.meesho_userId ?? ''
        console.log(sendToUser,"sendToUser2")
        TagManager.dataLayer({
            dataLayer: {
                event: ev,
                page_path: pathname,
                custom_user_id : sendToUser
            },
        });
    }
 
    const gtmCustomEvent = async (eName, args, hostname) => {
        let ev = eName;
        // console.log(eName, args, hostname,"eName, args, hostname")
        const user = JSON.parse(localStorage.getItem("user"));
        console.log(user,"userrr")
        if(host=="astroguru.bodhiness.com" || hostname=="astroguru.bodhiness.com"){
            ev = ev+"_astroguru";
        }
        else if(host=="mobafl.bodhiness.com" || hostname=="mobafl.bodhiness.com") {
            ev = ev+"_mobafl";
        }
        else if(host=="webj.bodhiness.com" || hostname=="webj.bodhiness.com"){
            ev = ev+"_webj";
        }
        else if(host=="lokal.bodhiness.com" || hostname=="lokal.bodhiness.com"){
            ev = ev+"_lokal";
        }
        else if(host=="namah.bodhiness.com" || hostname=="namah.bodhiness.com"){
            ev = ev+"_namah";
        }
        else if(host=="jio.bodhiness.com" || hostname=="jio.bodhiness.com"){
            ev = ev+"_jio";
        }
        else if(host=="meesho.bodhiness.com" || hostname=="meesho.bodhiness.com"){
            ev = ev+"_meesho";
        }
        const sendToUser = user?.meesho_userId ?? ''
        // console.log(sendToUser,"sendToUser")
        // console.log(ev,"ev")
        // console.log(host, hostname,"ev")
        TagManager.dataLayer({
            dataLayer: {
                event: ev,
                custom_user_id : sendToUser,
                ...args           
            }
        })
    }

    return (
        <AnalyticsContext.Provider value={{ gtmCustomEvent, gtmPageViewEvent }}>
            {props.children}
        </AnalyticsContext.Provider>
    )
}